import classNames from "classnames";
import { motion } from "framer-motion";

import styles from "./Loader.module.scss";

interface Props {
  className?: string;
}

const Loader: React.FC<Props> = ({ className }) => {
  return (
    <motion.img
      src={`${process.env.PUBLIC_URL}/images/theos.gif`}
      alt={"theos-logo"}
      className={classNames(styles["logo"], className)}
    />
  );
};

export default Loader;
