import { useQuery } from "@apollo/client";
import { ValueMetaobject, parseMetaobject, useShop } from "@whiteeespace/core";
import {
  Collection,
  CountryCode,
  GetCollectionsDataQuery,
  GetCollectionsDataQueryVariables,
  LanguageCode,
} from "gql/graphql";
import { GET_COLLECTIONS_DATA } from "queries/collections/get-collections-data";
import React from "react";
import { Navigate } from "react-router-dom";

const CollectionsRedirect: React.FC = () => {
  const { countryIsoCode, languageIsoCode } = useShop();
  const { data: collectionsData, loading: isCollectionsDataLoading } = useQuery<
    GetCollectionsDataQuery,
    GetCollectionsDataQueryVariables
  >(GET_COLLECTIONS_DATA, {
    variables: {
      country: countryIsoCode as CountryCode,
      language: languageIsoCode as LanguageCode,
    },
  });

  if (!collectionsData || isCollectionsDataLoading) return null;

  const collectionsTitle = parseMetaobject<ValueMetaobject>(
    collectionsData.shop?.name,
  );
  const redirectUrl = (
    collectionsData?.shop?.defaultCollection?.reference as Collection
  ).handle;

  if (!collectionsTitle || !redirectUrl) return null;
  return <Navigate to={`/${collectionsTitle.value}/${redirectUrl}`} replace />;
};

export default CollectionsRedirect;
