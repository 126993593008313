export enum SupportedLanguage {
  EN = "en",
  FR = "fr",
}

export const ROUTES = {
  en: {
    shop: "/shop",
    about_us: "/about-us",
    blog: "/blog",
    collections: {
      blank: "/collections",
      theos_bubbles: "/collections/theos-bubbles",
      j_arthur_collab: "/collections/j-arthur-collaboration",
      theos_beanies_class_of_24: "/collections/theos-beanie-class-of-24",
    },
    product: "/product",
    cart: "/cart",
    search: "/search",
    policies: {
      blank: "/policies",
      returns_and_exchanges: "/policies/returns-and-exchanges",
      terms_and_conditions: "/policies/terms-and-conditions",
      shipping_policy: "/policies/shipping",
      privacy_policy: "/policies/privacy",
    },
  },
  fr: {
    shop: "/shop",
    about_us: "/a-propos",
    blog: "/blogue",
    collections: {
      blank: "/collections",
      theos_bubbles: "/collections/bulles-de-theos",
      j_arthur_collab: "/collections/j-arthur-collaboration",
      theos_beanies_class_of_24: "/collections/theos-classe-de-24",
    },
    product: "/produit",
    cart: "/panier",
    search: "/chercher",
    policies: {
      blank: "/politiques",
      returns_and_exchanges: "/politiques/retours-et-exchanges",
      terms_and_conditions: "/politiques/termes-et-conditions",
      shipping_policy: "/politiques/livraison",
      privacy_policy: "/politiques/confidentialite",
    },
  },
};
