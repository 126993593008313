import CollectionsRedirect from "pages/collections/CollectionsRedirect";
import ShopRedirect from "pages/ShopPage/ShopRedirect";
import React from "react";
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ROUTES, SupportedLanguage } from "utils/routes";

const MINIMUM_LOAD_TIME = 1000;

const ShopLayout = React.lazy(async () => {
  return Promise.all([
    import("components/layouts/ShopLayout"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});
const BlogPage = React.lazy(async () => {
  return Promise.all([
    import("pages/BlogPage"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});
const PrivacyPolicy = React.lazy(async () => {
  return Promise.all([
    import("pages/policies/PrivacyPolicy"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});
const RefundPolicy = React.lazy(async () => {
  return Promise.all([
    import("pages/policies/RefundPolicy"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});
const ShippingPolicy = React.lazy(async () => {
  return Promise.all([
    import("pages/policies/ShippingPolicy"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});
const TermsOfService = React.lazy(async () => {
  return Promise.all([
    import("pages/policies/TermsOfService"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});
const AboutPage = React.lazy(async () => {
  return Promise.all([
    import("pages/AboutPage"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});
const HomePage = React.lazy(async () => {
  return Promise.all([
    import("pages/HomePage"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});
const ShopPage = React.lazy(async () => {
  return Promise.all([
    import("pages/ShopPage"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});
const ProductPage = React.lazy(async () => {
  return Promise.all([
    import("pages/ProductPage"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});

const CartPage = React.lazy(async () => {
  return Promise.all([
    import("pages/CartPage"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});
const TheosBubblesPage = React.lazy(async () => {
  return Promise.all([
    import("pages/collections/TheosBubblesPage"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});
const JArthurPresalePage = React.lazy(async () => {
  return Promise.all([
    import("pages/collections/JArthurPage"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});
const TheosBeaniePage = React.lazy(async () => {
  return Promise.all([
    import("pages/collections/TheosBeaniePage"),
    new Promise((resolve) => setTimeout(resolve, MINIMUM_LOAD_TIME)),
  ]).then(([moduleExports]) => moduleExports);
});

const SearchPage = React.lazy(() => import("pages/SearchPage"));
const ErrorPage = React.lazy(() => import("pages/ErrorPage"));

const createRoutes = (language: SupportedLanguage, index: number) => {
  return (
    <React.Fragment key={index}>
      <Route
        path={ROUTES[language]["shop"]}
        element={<ShopRedirect />}
        errorElement={ErrorPage}
      />
      <Route
        path={`${ROUTES[language]["shop"]}/:handle`}
        element={<ShopPage />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["search"]}
        element={<SearchPage />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["collections"]["blank"]}
        element={<CollectionsRedirect />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["collections"]["theos_bubbles"]}
        element={<TheosBubblesPage />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["collections"]["j_arthur_collab"]}
        element={<JArthurPresalePage />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["collections"]["theos_beanies_class_of_24"]}
        element={<TheosBeaniePage />}
        errorElement={ErrorPage}
      />

      <Route
        path={`${ROUTES[language]["product"]}/:productHandle`}
        element={<ProductPage />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["policies"]["blank"]}
        element={<Navigate to="/policies/shipping" replace />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["policies"]["shipping_policy"]}
        element={<ShippingPolicy />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["policies"]["returns_and_exchanges"]}
        element={<RefundPolicy />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["policies"]["terms_and_conditions"]}
        element={<TermsOfService />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["policies"]["privacy_policy"]}
        element={<PrivacyPolicy />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["cart"]}
        element={<CartPage />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["about_us"]}
        element={<AboutPage />}
        errorElement={ErrorPage}
      />
      <Route
        path={ROUTES[language]["blog"]}
        element={<BlogPage />}
        errorElement={ErrorPage}
      />
    </React.Fragment>
  );
};

const supportedLanguages: SupportedLanguage[] = [
  SupportedLanguage.EN,
  // SupportedLanguage.FR,
];
const localizedRoutes = supportedLanguages.map(createRoutes);

const RootRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<HomePage />} errorElement={ErrorPage} />
      <Route element={<ShopLayout />}>
        {localizedRoutes}
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </>,
  ),
);

export default RootRouter;
