import { useQuery } from "@apollo/client";
import { ValueMetaobject, parseMetaobject, useShop } from "@whiteeespace/core";
import {
  Collection,
  CountryCode,
  GetShopDataQuery,
  GetShopDataQueryVariables,
  LanguageCode,
} from "gql/graphql";
import { GET_SHOP_DATA } from "queries/get-shop-data";
import React from "react";
import { Navigate } from "react-router-dom";

const ShopRedirect: React.FC = () => {
  const { countryIsoCode, languageIsoCode } = useShop();
  const { data: shopData, loading: isShopDataLoading } = useQuery<
    GetShopDataQuery,
    GetShopDataQueryVariables
  >(GET_SHOP_DATA, {
    variables: {
      country: countryIsoCode as CountryCode,
      language: languageIsoCode as LanguageCode,
    },
  });

  if (!shopData || isShopDataLoading) return null;

  const collectionsTitle = parseMetaobject<ValueMetaobject>(
    shopData.shop?.name,
  );
  const redirectUrl = (shopData?.shop?.defaultSection?.reference as Collection)
    .handle;

  if (!collectionsTitle || !redirectUrl) return null;
  return <Navigate to={`/${collectionsTitle.value}/${redirectUrl}`} replace />;
};

export default ShopRedirect;
