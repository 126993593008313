import { AtomEffect, atom } from "recoil";

const localStorageEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const languageState = atom<string>({
  key: "language",
  default: "EN",
  effects: [localStorageEffect("language")],
});

export const countryState = atom({
  key: "country",
  default: "CA",
});
