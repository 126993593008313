import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WhiteeeShopifyProvider } from "@whiteeespace/core";
import PageLoader from "components/custom/PageLoader";
import { CountryCode, LanguageCode } from "gql/graphql";
import ClosedPage from "pages/ClosedPage";
import { Suspense, useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { countryState, languageState } from "states/metadataState";

import i18n from "./i18n";
import RootRouter from "./RootRouter";

import "./global.scss";

const queryClient = new QueryClient();

const App: React.FC = () => {
  const language = useRecoilValue(languageState);
  const country = useRecoilValue(countryState);
  const [isClosed, setIsClosed] = useState<boolean>(
    process.env.REACT_APP_IS_CLOSED === "true",
  );

  useEffect(() => {
    i18n.changeLanguage(language.toLowerCase());
  }, [i18n, language]);

  return (
    <WhiteeeShopifyProvider
      storefrontId={process.env.REACT_APP_STOREFRONT_ID!}
      storeToken={process.env.REACT_APP_PUBLIC_STOREFRONT_API_TOKEN!}
      countryCode={country as CountryCode}
      languageCode={language as LanguageCode}
    >
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Suspense fallback={<PageLoader />}>
            {isClosed ? (
              <ClosedPage setIsClosed={setIsClosed} />
            ) : (
              <RouterProvider router={RootRouter} />
            )}
          </Suspense>
        </HelmetProvider>
      </QueryClientProvider>
    </WhiteeeShopifyProvider>
  );
};
export default App;
